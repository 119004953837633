<template>
  <div class="sources-setting">
    <div class="header">
      <div class="titre">
        <h4>Gestion des Sources</h4>
      </div>
      <div class="button">
        <b-button
          size="sm"
          variant="light"
          v-if="sortDesc"
          class="searchIcon padd-btn"
          @click="sortBy"
        >
          <font-awesome-icon icon="caret-up" />
        </b-button>
        <b-button
          size="sm"
          variant="light"
          v-else
          class="searchIcon padd-btn"
          @click="sortBy"
        >
          <font-awesome-icon icon="caret-down" />
        </b-button>
        <b-button
          size="sm"
          variant="success"
          v-b-modal.addSourceModal
          class="searchIcon"
        >
          <font-awesome-icon class="" icon="plus" />
          Ajouter une source
        </b-button>
      </div>
    </div>
    <div class="input-search">
      <div class="block-search">
        <span class="searchIcon" title="search">
          <font-awesome-icon icon="search"
        /></span>
        <input
          class="search"
          type="text"
          placeholder="Rechercher un source"
          v-model="name_source"
        />
      </div>
      <div v-if="getSourceLoading" class="init-loading three-dots-loading mt-2">
        Chargement en cours
      </div>
    </div>

    <hr class="hr-source" />
    <b-list-group>
      <b-list-group-item
        v-for="source in computedSourceFilter"
        @click="source.visible = !source.visible"
        :key="source.id"
        :value="source.id"
      >
        <div class="content-source">
          <div class="name-gras">
            {{ source.name_internal }}
          </div>
          <div class="source-tags">
            <div
              class="source-tags-flex"
              v-if="
                source &&
                  source.pixelTypes &&
                  source.pixelTypes.data &&
                  source.pixelTypes.data.length
              "
            >
              <div v-for="item in source.pixelTypes.data" :key="item.id">
                <span v-if="!item.source_pixel_id" class="source-tag error">
                  <font-awesome-icon class="" icon="exclamation-circle" />
                  {{ item.name }}
                </span>
              </div>
            </div>
            <div v-else class="source-tags-flex">
              <div v-for="item in getListeTypePixel" :key="item.id">
                <span class="source-tag error">
                  <font-awesome-icon class="" icon="exclamation-circle" />
                  {{ item.name }}
                </span>
              </div>
            </div>
            <span v-if="source.active == 0" class="source-tag warning">
              <font-awesome-icon class="" icon="exclamation-triangle" />
              Désactivé
            </span>
          </div>
          <div class="btn-action">
            <b-button
              size="sm"
              variant="primary"
              title="Modifier"
              @click.prevent.stop="handleUpdateClick(source)"
            >
              <font-awesome-icon class icon="pencil-alt" />
            </b-button>
            <b-button
              size="sm"
              title="Supprimer"
              variant="danger"
              @click.prevent.stop="handleDeleteClick(source)"
            >
              <font-awesome-icon class icon="trash-alt" />
            </b-button>
          </div>
        </div>
      </b-list-group-item>
      <b-list-group-item
        class="aucun-source"
        v-if="
          (!computedSourceFilter || !computedSourceFilter.length) &&
            !getSourceLoading
        "
        >Aucun source</b-list-group-item
      >
    </b-list-group>
    <b-modal
      no-close-on-backdrop
      id="addSourceModal"
      ref="addSourceModal"
      title="Ajouter une source"
      :hide-footer="true"
      @show="resetModal"
      @hidden="resetModal"
    >
      <form @submit.prevent="ajouterSource">
        <b-form-group label="Nom" label-for="Nom-input">
          <b-form-input
            id="Nom-input"
            v-model="sourceToAdd.name"
            required
          ></b-form-input>
        </b-form-group>
        <div class="switch">
          <div>
            <b-form-group label="Interne"></b-form-group>
          </div>
          <div class="etat-switch">
            <b-form-checkbox
              switch
              v-model="sourceToAdd.internal"
            ></b-form-checkbox>
          </div>
        </div>
        <div class="switch">
          <div>
            <b-form-group label="Activé" label-for="pixel-input"></b-form-group>
          </div>
          <div class="etat-switch">
            <b-form-checkbox
              switch
              v-model="sourceToAdd.active"
            ></b-form-checkbox>
          </div>
        </div>
        <b-form-group
          v-if="sourceToAdd.internal == 0"
          label="Commerciaux sédentaires"
        >
          <multiselect
            v-model="sourceToAdd.comm_sed_id"
            :options="getProjectsUsersCommercialsSedentaires"
            placeholder="Rechercher Commercial sédentaire"
            :multiple="false"
            :close-on-select="true"
            :clear-on-select="true"
            :preserve-search="true"
            label="full_name"
            track-by="id"
            :preselect-first="false"
          >
            <span slot="noResult">Aucun Commercial sédentaire trouvé.</span>
            <span slot="noOptions">Aucun Commercial sédentaire trouvé.</span>
          </multiselect>
        </b-form-group>
        <b-card class="a-card mb-3">
          <div
            class="a-card-header"
            @click="subcategorie == true ? (view = !view) : null"
          >
            <div
              class="a-card-header-flex"
              :class="{
                'a-card-header-subcategorie': subcategorie == true
              }"
            >
              <div class="flex align-center">
                <div class="title-card">
                  Pixel
                </div>
              </div>

              <div v-if="subcategorie == true">
                <font-awesome-icon
                  icon="angle-down"
                  class="flesh-down"
                  v-if="view == false"
                />
                <font-awesome-icon
                  icon="angle-up"
                  class="flesh-up"
                  v-if="view == true"
                />
              </div>
            </div>
            <hr
              class="line-hr"
              :class="{
                'display-body': subcategorie == true && view == false
              }"
            />
          </div>
          <div
            class="a-card-body"
            :class="{
              'display-body': subcategorie == true && view == false
            }"
          >
            <div v-if="getListeTypePixel && getListeTypePixel.length">
              <div v-for="(item, index) in getListeTypePixel" :key="index">
                <b-form-group
                  :label="' Source Pixel -' + ' ' + item.name"
                  label-for="nom-input-update"
                >
                  <multiselect
                    v-model="sourceToAdd.typePixel[index]"
                    :options="pixelSelect[index] || []"
                    :placeholder="'Rechercher un pixel' + ' ' + item.name"
                    :multiple="false"
                    :close-on-select="true"
                    label="libelle"
                    track-by="id"
                    :loading="pixelSelectLoading"
                  >
                    <span slot="noResult">Aucune option trouvée.</span>
                    <span slot="noOptions">Aucune option trouvée.</span>
                  </multiselect>
                </b-form-group>
              </div>
            </div>
            <div v-else class="bleu-aucun">Aucun Type des Pixels</div>
          </div>
        </b-card>
        <div class="message">
          <div v-if="getSourceLoading" class="loading">
            Chargement en cours...
          </div>
          <div v-if="getSourceError" class="error">
            <ul v-if="Array.isArray(getSourceError)">
              <li v-for="(e, index) in getSourceError" :key="index">
                {{ e }}
              </li>
            </ul>
            <span v-else>{{ getSourceError }}</span>
          </div>
        </div>
        <div class="form-actions">
          <b-button
            @click="hideModal('addSourceModal')"
            variant="outline-secondary"
          >
            Annuler
          </b-button>
          <b-button variant="success" type="submit">
            Enregistrer
          </b-button>
        </div>
      </form>
    </b-modal>
    <b-modal
      no-close-on-backdrop
      id="updateSourceModal"
      ref="updateSourceModal"
      title="Modifier une source"
      :hide-footer="true"
      @hidden="resetModal"
    >
      <form v-if="sourceToUpdate" @submit.prevent="modifierSource">
        <b-form-group label="Nom" label-for="Nom-input">
          <b-form-input
            id="Nom-input"
            v-model="sourceToUpdate.name"
            required
          ></b-form-input>
        </b-form-group>
        <div class="switch">
          <div>
            <b-form-group label="Interne"></b-form-group>
          </div>
          <div class="etat-switch">
            <b-form-checkbox
              switch
              v-model="sourceToUpdate.internal"
            ></b-form-checkbox>
          </div>
        </div>
        <div class="switch">
          <div>
            <b-form-group label="Activé" label-for="pixel-input"></b-form-group>
          </div>
          <div class="etat-switch">
            <b-form-checkbox
              switch
              v-model="sourceToUpdate.active"
            ></b-form-checkbox>
          </div>
        </div>
        <b-form-group
          v-if="sourceToUpdate.internal == 0"
          label="Commerciaux sédentaires"
        >
          <multiselect
            v-model="sourceToUpdate.commercial_sedentaire"
            :options="getProjectsUsersCommercialsSedentaires"
            placeholder="Rechercher Commercial sédentaire"
            :multiple="false"
            :close-on-select="true"
            :clear-on-select="true"
            :preserve-search="true"
            label="full_name"
            track-by="id"
            :preselect-first="false"
          >
            <span slot="noResult">Aucun Commercial sédentaire trouvé.</span>
            <span slot="noOptions">Aucun Commercial sédentaire trouvé.</span>
          </multiselect>
        </b-form-group>
        <b-card class="a-card mb-3">
          <div
            class="a-card-header"
            @click="subcategorie == true ? (view = !view) : null"
          >
            <div
              class="a-card-header-flex"
              :class="{
                'a-card-header-subcategorie': subcategorie == true
              }"
            >
              <div class="flex align-center">
                <div class="title-card">
                  Pixel
                </div>
              </div>

              <div v-if="subcategorie == true">
                <font-awesome-icon
                  icon="angle-down"
                  class="flesh-down"
                  v-if="view == false"
                />
                <font-awesome-icon
                  icon="angle-up"
                  class="flesh-up"
                  v-if="view == true"
                />
              </div>
            </div>
            <hr
              class="line-hr"
              :class="{
                'display-body': subcategorie == true && view == false
              }"
            />
          </div>
          <div
            class="a-card-body"
            :class="{
              'display-body': subcategorie == true && view == false
            }"
          >
            <div v-if="getListeTypePixel && getListeTypePixel.length">
              <div v-for="(item, index) in getListeTypePixel" :key="index">
                <b-form-group
                  :label="' Source Pixel -' + ' ' + item.name"
                  label-for="nom-input-update"
                >
                  <multiselect
                    v-model="typePixelUpdate[index]"
                    :options="pixelSelect[index] || []"
                    :placeholder="'Rechercher un pixel' + ' ' + item.name"
                    :multiple="false"
                    :close-on-select="true"
                    label="libelle"
                    track-by="id"
                    :loading="pixelSelectLoading"
                  >
                    <span slot="noResult">Aucune option trouvée.</span>
                    <span slot="noOptions">Aucune option trouvée.</span>
                  </multiselect>
                </b-form-group>
              </div>
            </div>
            <div v-else class="bleu-aucun">Aucun Type des Pixels</div>
          </div>
        </b-card>
        <div class="message">
          <div v-if="getSourceLoading" class="loading">
            Chargement en cours...
          </div>
          <div v-if="getSourceError" class="error">
            <ul v-if="Array.isArray(getSourceError)">
              <li v-for="(e, index) in getSourceError" :key="index">
                {{ e }}
              </li>
            </ul>
            <span v-else>{{ getSourceError }}</span>
          </div>
        </div>
        <div class="form-actions">
          <b-button
            @click="hideModal('updateSourceModal')"
            variant="outline-secondary"
          >
            Annuler
          </b-button>
          <b-button variant="success" type="submit">
            Enregistrer
          </b-button>
        </div>
      </form>
    </b-modal>
    <b-modal
      no-close-on-backdrop
      ref="deleteSourceModal"
      id="deleteSourceModal"
      title=" Supprimer un source"
      :hide-footer="true"
      @hidden="resetModal"
    >
      <p>
        Êtes-vous certain de vouloir supprimer
        <strong> {{ sourceToDelete ? sourceToDelete.name : '' }}</strong>
        ?
      </p>
      <div class="message">
        <div v-if="getSourceLoading" class="loading">
          Chargement en cours...
        </div>
        <div v-if="getSourceError" class="error">
          <ul v-if="Array.isArray(getSourceError)">
            <li v-for="(e, index) in getSourceError" :key="index">
              {{ e }}
            </li>
          </ul>
          <span v-else>{{ getSourceError }}</span>
        </div>
      </div>
      <div class="form-actions">
        <b-button
          @click="hideModal('deleteSourceModal')"
          variant="outline-secondary"
        >
          Annuler
        </b-button>
        <b-button variant="success" @click="supprimerSource">
          Valider
        </b-button>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  data() {
    return {
      sourceToAdd: {
        name: null,
        active: true,
        internal: false,
        comm_sed_id: null,
        typePixel: []
      },
      view: false,
      subcategorie: true,
      name_source: null,
      sourceToUpdate: null,
      sourceToDelete: null,
      pixelSelect: [],
      typePixelUpdate: [],
      pixelSelectLoading: false,
      sortDesc: false,
      sort_by: 'full_name',
      sort_by_desc: 'full_name'
    }
  },
  methods: {
    ...mapActions([
      'addSource',
      'updateSource',
      'deleteSource',
      'getListSource',
      'resetErrorSource',
      'fetchUsersCommercialSedentaire',
      'fetchUsersCommercialsIte',
      'fetchPixelSources',
      'fetchListTypePixel'
    ]),
    sortBy() {
      this.sortDesc = !this.sortDesc
      if (!this.sortDesc) {
        this.getListSource({
          sort_by: this.sort_by,
          full_name: this.full_name
        })
      } else {
        this.getListSource({
          sort_by_desc: this.sort_by_desc,
          full_name: this.full_name
        })
      }
    },
    resetModal() {
      this.sourceToAdd = {
        name: null,
        active: true,
        internal: false,
        comm_sed_id: null,
        typePixel: []
      }
      this.view = false
      this.subcategorie = true
      this.sourceToUpdate = null
      this.sourceToDelete = null
      this.typePixelUpdate = []
      this.resetErrorSource()
    },
    hideModal(ref) {
      this.$refs[ref].hide()
      this.resetModal()
    },
    handleUpdateClick(source) {
      this.sourceToUpdate = { ...source }
      this.typePixelUpdate = []
      if (
        this.sourceToUpdate.pixelTypes &&
        this.sourceToUpdate.pixelTypes.data &&
        this.sourceToUpdate.pixelTypes.data.length &&
        this.pixelSelect &&
        this.pixelSelect.length
      ) {
        for (
          let index = 0;
          index < this.sourceToUpdate.pixelTypes.data.length;
          index++
        ) {
          for (let j = 0; j < this.getListeTypePixel.length; j++) {
            if (
              this.getListeTypePixel[j].id ==
                this.sourceToUpdate.pixelTypes.data[index].id &&
              this.sourceToUpdate.pixelTypes.data[index].source_pixel_id &&
              this.pixelSelect &&
              this.pixelSelect.length
            ) {
              for (let i = 0; i < this.pixelSelect.length; i++) {
                const pixel = this.pixelSelect[i].find(
                  e =>
                    e.id ==
                    this.sourceToUpdate.pixelTypes.data[index].source_pixel_id
                )
                if (pixel) {
                  this.typePixelUpdate[j] = pixel
                }
              }
            }
          }
        }
      }
      this.$refs['updateSourceModal'].show()
    },
    handleDeleteClick(source) {
      this.sourceToDelete = source
      this.$refs['deleteSourceModal'].show()
    },
    async ajouterSource() {
      this.sourceToAdd.idPixel = this.getListeTypePixel.filter(item => {
        return item.id
      })
      const response = await this.addSource({ source: this.sourceToAdd })
      if (response) {
        this.fetchPixelRegies()
        this.hideModal('addSourceModal')
      }
    },
    async modifierSource() {
      this.sourceToUpdate.idPixel = this.getListeTypePixel.filter(item => {
        return item.id
      })
      for (
        let index = 0;
        index < this.sourceToUpdate.pixelTypes.data.length;
        index++
      ) {
        this.sourceToUpdate.pixelTypes.data[index].source_pixel_id =
          this.typePixelUpdate[index] && this.typePixelUpdate[index].id
            ? this.typePixelUpdate[index].id
            : ''
      }
      const response = await this.updateSource({
        source: this.sourceToUpdate
      })
      if (response) {
        this.hideModal('updateSourceModal')
      }
    },
    async supprimerSource() {
      const response = await this.deleteSource({
        source: this.sourceToDelete
      })
      if (response) {
        this.hideModal('deleteSourceModal')
      }
    },
    async fetchPixelRegies() {
      if (!this.getListeTypePixel || !this.getListeTypePixel.length) {
        await this.fetchListTypePixel()
      }
      this.pixelSelectLoading = true
      for (let index = 0; index < this.getListeTypePixel.length; index++) {
        this.fetchPixelSources({
          pixel_id: this.getListeTypePixel[index].id
        }).then(data => {
          this.pixelSelect[index] = data
          this.pixelSelectLoading = false
        })
      }
    }
  },
  computed: {
    ...mapGetters([
      'getSources',
      'getSourceLoading',
      'getSourceError',
      'getProjectsUsersCommercialsSedentaires',
      'getProjectsUsersCommercialsIte',
      'getListeTypePixel'
    ]),
    computedSourceFilter: function() {
      if (this.name_source) {
        return this.getSources.filter(item => {
          return this.name_source
            .toLowerCase()
            .split(' ')
            .every(name => item.name.toLowerCase().includes(name))
        })
      } else {
        return this.getSources
      }
    }
  },
  async mounted() {
    this.getListSource({
      sort_by: this.sort_by
    })
    this.fetchUsersCommercialSedentaire()
    this.fetchUsersCommercialsIte()
    this.fetchPixelRegies()
    this.fetchListTypePixel()
  }
}
</script>
<style lang="scss" scoped>
.sources-setting {
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    white-space: nowrap;
    .button {
      display: flex;
      .searchIcon {
        border-radius: unset;
        box-shadow: unset;
        cursor: pointer;
        border: 1px solid #6c757d59;
        &:focus {
          box-shadow: unset;
        }
        &.padd-btn {
          padding: 0px 9px;
        }
      }
    }
  }
  .input-search {
    margin-top: 15px;
    display: flex;
    align-items: center;
    .block-search {
      cursor: pointer;
      .search {
        border: 1px solid #dadada;
        outline: none;
        font-size: 16px;
        height: 40px;
        background: #fff;
        padding-left: 10px;
      }
      .searchIcon {
        padding: 10px;
        border: 1px solid #d6d8db;
        font-size: 16px;
        background: #d6d8da17;
      }
    }
    .init-loading {
      margin-left: 50px;
    }
  }
  .hr-source {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  .list-group {
    .aucun-source {
      color: #007bff;
      text-align: center;
    }
    .list-group-item {
      .content-source {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .name-gras {
          text-transform: capitalize;
          font-weight: bold;
          width: 300px;
        }
        .source-tags {
          display: flex;
          align-items: center;
          .source-tags-flex {
            display: flex;
            align-items: center;
          }
          .source-tag {
            display: inline-block;
            padding: 0.25em 0.4em;
            font-size: 75%;
            font-weight: 700;
            line-height: 1;
            text-align: center;
            white-space: nowrap;
            vertical-align: baseline;
            border-radius: 0.25rem;
            margin-left: 5px;
            &.warning {
              color: #f39f2d;
              background-color: #dc800129;
              border: 1px solid #f18e06;
            }
            &.error {
              color: #ff6961;
              background-color: #ff696133;
              border: 1px solid #ff6961;
            }
          }
        }
        .btn-action {
          display: block;
          margin-right: 20px;
          // white-space: nowrap;
          button {
            margin: 0 2px;
          }
        }
      }
    }
  }
  @media screen and (max-width: 786px) {
    .header {
      white-space: unset;
    }
    .list-group {
      .list-group-item {
        .content-source {
          display: flex;
          .source-tags {
            display: block;
            .source-tags-flex {
              display: block;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 400px) {
    .header {
      white-space: unset;
    }
    .searchIcon {
      padding: 10px;
    }
    .block-search {
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
      .search {
        width: 100%;
        font-size: 12px;
      }
    }
    .list-group {
      .list-group-item {
        .content-source {
          display: block;
          .name-gras {
            width: 100%;
          }
          .source-tags {
            display: block;
            .source-tags-flex {
              margin-bottom: 6px;
              margin-top: 4px;
              display: block;
            }
          }
        }
      }
    }
  }
}
#addSourceModal,
#updateSourceModal {
  .bleu-aucun {
    text-align: center;
    color: #007bff;
    font-size: 14px;
  }
  .a-card {
    margin-top: 13px;
  }
  .a-card-header {
    cursor: pointer;
    .a-card-header-flex {
      display: flex;
      align-items: center;
      &.a-card-header-montant,
      &.a-card-header-type-project,
      &.a-card-header-subcategorie {
        justify-content: space-between;
      }
      .title-card {
        font-size: 16px;
      }
      .style-title-type {
        font-size: 12px;
        font-weight: bold;
      }
      .type-projet {
        font-weight: bold;
      }
      .body-card {
        .value-ttc {
          margin-left: 12px;
        }
      }
      .delete-project-card {
        margin-left: 207px;
        .delete-project {
          a {
            color: #ff5722;
            font-weight: bold;
          }
        }
      }
    }
    .line-hr {
      margin-top: 10px;
    }
  }
  .card-body {
    padding: 7px 11px 7px 11px;
    .show-button {
      background-color: #e5e5e580;
      border: 1px solid #6c757d52;
      margin-left: 270px;
      text-align: center;
      padding: 3px;
      border-radius: 10px;
      font-weight: bold;
      min-width: 50px;
      max-width: 100px;
    }
  }
  .display-body {
    display: none;
  }
  .switch {
    display: flex;
    .etat-switch {
      margin-left: 25px;
    }
  }
}
</style>
